import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = BaseCoverBox.Prose;

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: 'bg-white p-0 wings-white sm:p-0',
    headline: 'text-headline-lg sm:text-headline-xl',
    toggle: 'text-primary-700 bg-white text-body-sm sm:text-body-sm',
  },
});

export default Object.assign(CoverBox, { Prose });
