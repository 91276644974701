import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTrendingMenuTheme from 'base/components/TrendingMenu/theme';

const Tag = tw.theme({
  extend: TagTheme,
  defaultVariants: {
    size: 'small',
  },
});

const TrendingMenu = tw.theme({
  extend: BaseTrendingMenuTheme,
  slots: {
    base: `bg-white px-3 wings wings-white`,
    headline: `mt-1 whitespace-nowrap uppercase text-headline-sm`,
    prefix: `text-red-700`,
  },
});

export default Object.assign(TrendingMenu, { Tag });
